<template>
    <section class="viviendas-ver-residentes">
        <div class="row mb-4">
            <div class="col-auto">
                <h4>Residentes de esta vivienda</h4>
            </div>
            <div class="col-auto ml-sm-auto">
                <button class="btn-general btn btn-sm f-14" @click="crearNuevoResidente">
                    Nuevo residente
                </button>
            </div>
        </div>
        <div class="col-12 overflow-auto custom-scroll" style="height:calc(100vh - 250px)">
            <!-- tipo1 -->
            <div class="row mt-3">
                <div v-for="(residente,i) in residentes" :key="i" class="col-auto mb-2">
                    <card-residente :residente="residente" class="pb-2 pt-3 px-3 cr-pointer" @actualizar="obtenerResidentes" />
                </div>
            </div>
            <!-- tipo2 -->
            <!-- <div class="col-auto mb-2">
                <div class="card-residente border br-8 pb-2 pt-3 px-3">
                    <div class="row">
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="like-img bg2 br-50 d-middle-center f-30 mx-auto">
                                <i class="icon-account-outline op-05" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-9 col-md-9 col-lg-9 text-center text-sm-left pl-0">
                            <p class="f-500 f-20">Karina Ardila</p>
                            <p class="f-14 text-86">Residente - Vivienda propia</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3 d-none d-sm-block"> </div>
                        <div class="col-sm-9 col-md-9 col-lg-9 pl-lg-0 pl-sm-0">
                            <div class="d-flex justify-content-center justify-content-sm-start">
                                <div class="d-flex d-middle">
                                    <div class="like-img-mini bg-phone br-50 d-middle-center f-17 mr-2">
                                        <i class="icon-cellphone op-05" />
                                    </div>
                                    <div class="lh-18">
                                        <p class="f-12 text-86">Número de celular</p>
                                        <p class="f-500 f-14">+57 318 123 4567</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- partials -->
        <modal-eliminar ref="abrirEliminarResidente" titulo="Eliminar residente" mensaje="¿Desea eliminar este residente?" adicional="Rechazar" tamano="modal-sm">
            No podrá acceder a Mi Conjunto y todo registro será borrado del sistema
        </modal-eliminar>
    </section>
</template>
<script>
import Viviendas from '~/services/viviendas'

export default {
    data(){
        return{
            residentes:[]
        }
    },
    created(){
        this.obtenerResidentes()
    },
    methods:{
        crearNuevoResidente(){
            this.$router.push({name:'viviendas.guardar.residente'})
        },
        editarResidente(){
            this.$router.push({name:'viviendas.crear.residente'})
        },
        eliminarResidente(){
            this.$refs.abrirEliminarResidente.toggle()
        },
        async obtenerResidentes(){
            try {
                let params={
                    id_vivienda : this.$route.params.id_vivienda
                }
                const {data} = await Viviendas.residentes(params)
                this.residentes = data.data.data
            
            } catch (e){
                this.errorCatch(e)
            }
          
        },
    }
}
</script>
<style lang="scss" scoped>
.viviendas-ver-residentes{
    .card-residente{
        max-width: 350px;
        width: 350px;
        .like-img{
            width: 52px;
            height: 52px;
            i::before{
                margin-right: 0px !important;
                margin-left: 0px !important;
            }
        }
        .bg1{
            background: #DBDBDB;
        }
        .bg2{
            background: #80DCFF;
        }
        .bg3{
            background: #FFD54F;
        }
        .bg-phone{
            background: #FFF1C4;
        }
        .bg-gender{
            background: #AAC3FF;
        }
        .like-img-mini{
            width: 32px;
            height: 32px;
            i::before{
                margin-right: 0px !important;
                margin-left: 0px !important;
            }
        }
        .mas-opciones{
            width: 32px;
            height: 32px;
            background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
            border: 1px solid #CFD7DF;
        }
       
    }
}
.el-tooltip__popper.is-light{
    border: none !important;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 4px;
    .tooltip-editar-residente{
        min-width: 280px;
    }
}
</style>